<template>
  <div class="page">
    <el-descriptions
      class="margin-top"
      title=""
      :column="2"
      size="medium"
      border
    >
      <el-descriptions-item label="昵称">{{ userInfo.nickName }}</el-descriptions-item>
      <el-descriptions-item label="联系方式">{{ userInfo.userMobile }}</el-descriptions-item>
      <el-descriptions-item label="用户ID">{{ userInfo.memberId }}</el-descriptions-item>
      <el-descriptions-item label="最新订单">{{ (userInfo.startTime||'')+(userInfo.startTime?' ~ ':'')+(userInfo.endTime||'')  }}</el-descriptions-item>
      <el-descriptions-item label="累计完成订单时长">{{ userInfo.total || "0" }}</el-descriptions-item>
      <el-descriptions-item label="消费时长最多的门店">{{ userInfo.durationMaxShopName }}</el-descriptions-item>
      <el-descriptions-item label="消费次数最多的门店">{{ userInfo.countMaxShopName }}</el-descriptions-item>
      <el-descriptions-item label="消费总额"> <span
          >微信消费：¥{{ userInfo.wechatConsumeAmount }} ｜本金消费：¥{{
            userInfo.balanceConsumeAmount
          }}｜赠金消费：¥{{ userInfo.giveConsumeAmount }} ｜线下消费：¥{{
            userInfo.cashConsumeAmount
          }}｜团购消费：¥{{ userInfo.buyConsumeAmount }}</span
        ></el-descriptions-item>
      <el-descriptions-item label="账户余额"><span
          >本金：¥{{ userInfo.balanceAmount }} ｜门店赠金：¥{{
            userInfo.giveAmount
          }}</span
        ></el-descriptions-item>
      <el-descriptions-item label="历史累计充值本金">{{'￥'+ userInfo.historyTotalRecharge }}</el-descriptions-item>
      <el-descriptions-item label="累计充值（本金）">{{'￥'+ userInfo.totalRecharge }}</el-descriptions-item>
      <el-descriptions-item label="成长值">{{userInfo.memberLevelVo?userInfo.memberLevelVo.experience:'' }}</el-descriptions-item>
      <el-descriptions-item label="会员等级">{{userInfo.memberLevelVo.levelConfigVo?userInfo.memberLevelVo.levelConfigVo.levelName:''  }}</el-descriptions-item>
    
    </el-descriptions>

   
  </div>
</template>

<script>
import { getPUserInfo } from "@/api/user/user";
export default {
  data() {
    return {
      userInfo:{
        memberLevelVo:{
          levelConfigVo:{}
        }
      },
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    // 【请求】添加
    getUserInfo() {
      let data = this.$route.query;
      getPUserInfo(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.userInfo = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-item {
  width: 500px;
  height: 48px;
  margin: 15px;
  border: 1px solid #eee;
  display: inline-flex;

  p {
    min-width: 200px;
    background: #f9f9f9;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  span {
    display: inline-flex;
    align-items: center;
    padding: 0 50px;
  }
}
.info-item2 {
  width: 1030px;
}
</style>
